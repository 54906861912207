import { useState, useRef, useEffect } from "react";
import { Container, Row, Card, Button, Form } from "react-bootstrap";
import {
  useNavigate, useSearchParams,
} from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { NwLoaderSpinner } from "../_utilityComponents/NwIcons/NwIcons";
import { getBackendUrl } from "../../config";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const [collapsedSideBar, setCollapsedSideBar] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [isLoading,setIsLoading] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const emailInputRef = useRef("test@test.com");

  useEffect(() => {
    const email = searchParams.get('email');
    
    if (email && emailInputRef.current) {
        emailInputRef.current.value = email; // Set the value of the input directly
    }
  }, []);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const email = formData.get('email');
    setIsLoading(true);

    //send the email here
    const editedData = { userEmail: email};
    
    fetch(getBackendUrl("api-user-forgot-password"), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedData), 
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {

        Swal.fire(
            t("loginPage.formSection.form.emailSendSuccessText"), 
            t("loginPage.formSection.form.emailSendText") + email, 
            "success"
        ).then((result) => {
            setIsLoading(false);
            // This function will be called when the user clicks "OK" on the dialog
            if (result.isConfirmed) {
                // Perform your action here
                navigate("/");
            }
        });
      })
      .catch((error) => {
        Swal.fire("Error", " ", "error");
        // console.error("There was a problem with the PUT request:", error);
      });


    //Email sent
    
    
  };

  return (
    <Container>
      <Row>
        <div className="logo d-flex justify-content-center" style={{ marginTop: '20px',color: '#0D6DFD' }}>
          <NwLoaderSpinner size={collapsedSideBar ? 41 : 30} />
        </div>

        <div className="fpCard d-flex justify-content-center" style={{ marginTop: '20px' }}>
          <Card style={{ width: '30rem' }}>
            <Card.Body>
              <Card.Title>
                {t("loginPage.formSection.form.forgotPasswordText")}
              </Card.Title>

              <Card.Text>
                {t("loginPage.formSection.form.forgotPasswordText2")}
              </Card.Text>
              <Form id="trouble-signing-form" onSubmit={handleSubmit}>
                <Form.Group controlId="email">
                  <Form.Label>{t("editProfile.email")}</Form.Label>
                  <Form.Control type="email" name="email" ref={emailInputRef} />
                </Form.Group>
                <br></br>
                <Button type="submit">
                    {isLoading ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span
                                style={{ marginRight: "10px" }} // Adjust spacing as needed
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span>{t("loading")}</span>
                        </div>
                    ) : (
                        t("loginPage.formSection.form.forgotPasswordSubmitButton")
                    )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Row>
    </Container>
  );
}
